/* Font size base */
$font-size-base: 14px;

/* New colors which are not in the index.css */
$widget-background-color: #f3f3f3;
$grey-4-color: #50616a;
$whitegrey-border: #e6e6e7;
$grey-2-border: #a4b5bb;
$grey-6-color: #2c3f46;
$ice-blue-background: #93d4ee;
$sea-green-background: #99ebd6;
$grey-secondary-color: #666;
$cobalt-blue: #728bd1;
$primary-grey-black: #d7d7d7;
$white-grey: #f9f9f9;
$basic-grey: #ccc;
$calendar-border: #e6e9fa;
$light-blue-background: rgba(0, 130, 160, 0.16);
$light-grey-background: rgba(0, 0, 0, 0.04);
$light-black-border: rgba(0, 0, 0, 0.08);
$success-green: #008000;
$error-red: #B30000;
$petrol-blue: #007da4;
$grey-highlights: #DADADB;
$light-white-background: rgba(255,255,255, 0.35);
$dark-grey-permissions-title: #303036;