.breadcrumb {
    align-items: center;
    background: var(--c-white);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-start;
    min-height: 2.5rem;
    padding: .5rem 0;

    a, p {
        color: var(--c-blue-mid-3);
        padding: 0 .5rem;
        text-decoration: underline;

        &.dashboard {
            padding-left: 0;
        }
    }

    p {
        color: var(--c-grey-light-1);
    }

    img {
        min-width: 1.1rem;

        &.icon {
            min-width: 1.3rem;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .breadcrumb {
        padding: .5rem 0;
        
        .bc-container {
            padding: 0 2.5rem;
        }
    }
}