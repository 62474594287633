@import '../../styles/variables';

/* ============================== */
/* Dashboard                      */
/* ============================== */

/* Columns */
.dashboard {
    .bc-cols {
        margin: 0;

        .bc-col {
            padding: 0;
        }
    }
}

/* Widget generic style */
.widget {
    align-items: flex-start;
    background: $widget-background-color;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.25rem;
    padding: 1.5rem;
    position: relative;
    width: 100%;

    .widget-content {
        width: 100%;
    }

    h2 {
        color: $grey-4-color;
        font-size: 1.25rem;
        margin: 0;
    }

    .dismiss-btn {
        line-height: 1.625rem;
        padding: 0;
        position: absolute;
        right: 1.5rem;
    }

    /* CTA button in Widget */
    .cta {
        align-items: flex-start;
        border-top: .1rem solid $whitegrey-border;
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;
        padding-top: 1rem;
        width: 100%;

        &.no-border {
            border: none;
        }

        &.no-space {
            margin: 0;
            padding: 0;
        }
    }

    /* Limit Notifications Widget Height*/
    .notifications-list {
        max-height: 50rem;
    }

    .generic-message {
        margin: 0;
        width: 100%;
    }

    .bc-btn--secondary:not(:hover):not(:focus),
    .bc-btn--secondary:focus:not(:hover) {
        background-color: rgba($color: #fff, $alpha: 0.4);
    }

    // Users overview exception
    &.users-overview {
        gap: 0;
    }
}

/* ============================================================ */
/* Responsive                                                   */
/* ============================================================ */

@media only screen and (min-width: 768px) {
    .bc-select {
        margin-bottom: 0;
    }

    .widget {
        .widget-title {
            display: flex;
            justify-content: space-between;

            .dismiss-btn {
                padding: 0 .5rem;
            }
        }

    }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
    .dashboard {
        .bc-cols {
            &.first-level {
                margin-left: -0.625rem;
                margin-right: -0.625rem;

                .bc-col {
                    padding: 0 .625rem;
                }
            }

            &.second-level {
                .bc-col {
                    padding: 0;
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .bc-select {
        margin-top: 0;
    }

    .widget {
        margin-bottom: 2.5rem;
    }

    .dashboard {
        .bc-cols {
            --gutters: 1.25rem;
            margin: calc(-1 * var(--gutters));

            .bc-col {
                padding: calc(1 * var(--gutters));
            }
        }
    }
}