/* Generic message (Empty result, Error...) */
@import '../../styles/variables';

.generic-message {
	background-color: $light-grey-background;
	border-radius: .5rem;
	display: inline-flex;
	margin: 2.5rem 0;
	padding: 1.25rem;
	width: auto;

	img {
		width: 2.25rem;
		height: 2.25rem;
		margin-right: 1.25rem;
	}

	div {
		display: flex;
		flex-direction: column;
		gap: 0.375rem;

		h5 {
			color: #b30000;
			font-weight: 700;
			font-size: 1.25rem;
			font-style: normal;
			font-weight: 700;
			line-height: 1.625rem;
			margin: 0;
		}

		h6 {
			color: var(--c-default-text);
			font-size: 1rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.5rem;
			margin: 0;
		}
	}

	&.empty {
		h5 {
			color: $grey-4-color;
		}
	}

	&.success,
	&.empty-notifs {
		h5 {
			color: $success-green;
		}
	}

	&.info {
		h5 {
			color: $grey-4-color;
		}
	}

	&.warning {
		h5 {
			color: var(--c-warning-text);
		}
	}
}