@import '../../styles/mixin';

.back-to-top {
    @include flex-center;
    background: rgba(255, 255, 255, 0.10);
    border-radius: 100%;
    bottom: 3rem;
    cursor: pointer;
    height: 3rem;
    opacity: 0;
    position: fixed;
    right: 1rem;
    transition: .5s all ease-in;
    visibility: hidden;
    width: 3rem;    

    &.show {
        opacity: 1;
        visibility: visible;
    }

    img {
        width: 1.125rem;
    }
}

@media only screen and (min-width: 768px) {
    .back-to-top {
        height: 5rem;
        width: 5rem;

        img {
            width: 1.375rem;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .back-to-top {
        right: 2.5rem;
    }

}