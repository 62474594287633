@import '../../styles/variables';

.backdrop {
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9;
}

.popup-container.unsaved-data-confirmation-popup-active .popup {
	.popup-header {
		h3 {
			font-size: 1.375rem;
		}

		h4 {
			font-size: 1.25rem !important;
		}
	}
}

.popup-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	left: 50%;
	padding: 1rem;
	position: fixed;
	top: 0;
	transform: translateX(-50%);
	width: 51rem;
	z-index: 10;

	&.manage-applications {
		width: 66rem;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.popup {
		background-color: var(--c-default-bg);
		border-radius: 0.3rem;
		margin: 1rem 0;
		max-height: calc(100vh - 2rem);
		overflow-y: auto;
		padding: 1.25rem;
		position: relative;
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
		width: 100%;
		z-index: 9;

		&.open-permissions {
			.btn-container {
				margin-top: 0
			}
		}

		/* Permissions popup for internal user */
		.bc-accordion {
			.permissions {
				h4 {
					color: var(--c-default-text);
					font-weight: 700;
					margin-bottom: .5rem;
				}
			}
		}

		h3 {
			color: $grey-4-color;
			margin: 0;
			margin-bottom: 0.5rem;
			padding-right: 2.5rem;
		}

		h4 {
			color: $grey-4-color;
			font-size: 1.25rem;
			font-weight: 300;
			margin: 0.25rem 0 1.5rem;
		}

		.btn-container {
			border-top: 1px solid $grey-2-border;
			padding-top: 2rem;

			&.no-border {
				border-top: 0;
			}

			&.hide {
				display: none !important;
			}
		}

		.detail {
			color: var(--c-details);
			margin-right: auto;
			padding-right: 1rem;
		}

		.closePopup {
			position: absolute;
			right: 1rem;
			top: 1rem;
			z-index: 999;

			.bc-icon-cross {
				font-size: 1.25rem;
			}
		}

		&.linked-parties {
			height: inherit;
			.popup-content {
				height: inherit;
			}
		}

		&.manage-applications {
			height: inherit;
			.popup-content {
				height: inherit;
			}
		}

		&.hidden {
			opacity: 0;
			transform: translate(0, -50%) scale(0.9);
			pointer-events: none;
		}

		.generic-message {
			display: flex;
			margin: 1rem 0 0;
		}

		.loading-overlay {
			background: $light-white-background;
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: center;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.reject-access-content {
			align-items: flex-start;
			background: $light-grey-background;
			border-radius: .5rem;
			display: flex;
			gap: 1rem;
			padding: 1rem;

			.bc-textarea {
				width: 75%;
			}

			h5 {
				margin-top: 0;
			}
		}

		/* Edit user profile popup */
		.edit-user-content {
			h4 {
				margin-bottom: 1rem;
			}
		}

		/* Company popup */
		&.company {
			overflow-y: hidden;

			.popup-header {
				bottom: initial;
				top: 0;

				h4 {
					margin-bottom: 0;
				}
			}

			.popup-content {
				height: calc(100% - 11rem);
				overflow-y: auto;
			}
		}

		/* Manage applications (internal user) */
		&.manage-applications {
			.popup-header {

			}

			.popup-content {
				.search-bar {
					width: 60%;
				}

				.accordion-section-list.border {
					height: calc(100% - 12.5rem);
					overflow-y: auto;
				}

				&.error {
					height: auto;
				}
			}
		}
	}

	.popup:has(.socialmedia) {
		padding: 0;
		max-height: calc(100% - 2rem);
		max-width: 36.25rem;

		.socialmedia {
			display: flex;
			flex-direction: column;
			max-height: 100%;

			img {
				width: 100%;
				max-width: 100%;
				height: auto;
				border-top-left-radius: 0.3rem;
				border-top-right-radius: 0.3rem;
			}

			.social-media-content {
				overflow-y: auto;

				p {
					margin: 1rem;
					word-break: break-word;
					text-align: left;

					a {
						margin-left: 0.25rem;
					}

					&.social-media-text {
						white-space: pre-wrap;
					}
				}

				p:first-of-type::first-line {
					font-weight: 800;
				}
			}
		}
	}

	&.popup-full-height {
		overflow: hidden;

		.popup {
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.popup-content {
				margin-top: 1rem;
				overflow-y: auto;
			}

			.popup-header {
				left: 0;
				position: sticky;
				top: 0;
				width: 100%;

				.closePopup {
					top: 0;
					right: 0;
				}
			}

		}
	}

	&.overflow {
		overflow-y: auto;
	}
}