@import '../../styles/variables';

footer {
    background-color: $grey-6-color;
    border-radius: 1rem 1rem 0 0;
    color: var(--c-default-bg);
    margin-top: 2rem;
    padding: 2rem 0 1.5rem;

    p {
        font-size: 0.875rem;
        margin-top: 1rem;
    }

    .footer-links {
        border-top: .1rem solid rgba(255, 255, 255, 0.12);
        display: flex;
        margin-top: 1.25rem;

        li {
            padding: 1rem .75rem;

            a {
                border-bottom: .1rem solid transparent;
                font-size: .8125rem;
                transition: border-bottom-color .3s;

                &:hover {
                    border-bottom-color: var(--c-default-bg);
                    color: var(--c-default-bg);
                }
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    footer {
        margin-top: 0;
        padding: 2rem 1.5rem 1.5rem;
        ;
    }
}