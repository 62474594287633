@import './variables';

/* ============================== */
/* Override default styles        */
/* ============================== */

/* Override font size base */
html,
body {
    height: 100%;
    margin: 0;
}

html {
    font-size: $font-size-base;
}

body {
    color: $grey-6-color;
}

.bc-container {
    max-width: 125rem;
    padding: 0 1rem;

    .bc-step {
        align-items: flex-start;
        flex-direction: column;

        h1 {
            padding-right: .5rem;
        }
    }
}

main.bc-container {
    min-height: calc(100vh - 25rem);
}

h1 {
    color: $grey-6-color;
    font-size: 1.875rem;
    margin: 1.25rem 0 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.frequently-visited-list {
        margin-left: -1rem;
    }

    &.with-bullets {
        list-style: disc;
        margin-left: .5rem;
        padding-left: 1rem;
    }
}

p {
    margin: 0;
}

a {
    text-decoration: none;

    &.underline {
        text-decoration: underline;
    }
}

button {
    background: none;
    border: none;
    cursor: pointer;
}

iframe {
    border: none;
}

.bc-btn--primary {
    box-shadow: none;
}

.bc-btn--primary:focus {
    color: var(--c-default-bg);
    background-color: var(--c-blue-mid-3);
}

.litepicker .container__days .day-item.is-today {
    color: var(--c-default-bg) !important;
}

.litepicker .container__days div {
    width: calc(100%/7) !important;
}

.bc-select select:disabled {
    color: var(--c-details);
}

.loading-page {
    height: 100vh;
}

/* Tertiary button adjustment */
.bc-btn--tertiary.margin-left-negative {
    margin-left: -1rem;
}

/* Table */
.bc-table {
    .bc-icon-sorting {
        cursor: pointer;
    }
}

/* Pagination */
.bc-table__wrap {
    margin-top: 1rem;

    .bc-table__footer {
        margin: 0;

        .bc-table__footer__left,
        .bc-pagination {
            .bc-select {
                margin-bottom: 0;
                margin-top: 0;
                width: auto;
            }
        }
    }
}

/* Links Focus fix */
a:focus:not(.bc-btn--primary):not(.bc-btn--secondary):not(.bc-btn--tertiary):not(:hover) {
    color: initial;
}

.bc-btn--secondary:focus:not(:hover) {
    background: white;
    border-color: var(--c-blue-mid-3);
}

.bc-btn--tertiary:focus:not(.bc-btn--icon):not(:disabled):not(:hover) {
    border-color: transparent;
    margin-right: 0.5rem;

    &.bc-btn--icon-right>.bc-icon--right {
        background-color: var(--c-blue-tr-1);
        border-radius: var(--radius-circle);
        margin-left: .5rem;
    }
}

/* ==== TOAST ==== */
.bc-toast-wrap {
    z-index: 1000;
}

/* ============================== */
/* New styles                     */
/* ============================== */

.pointer-none {
    pointer-events: none;
}

.flex-50 {
    flex: 0 0 50%;
}

.margin-left-14 {
    margin-left: .875rem;
}

.bc-link {
    text-decoration: underline;
    transition: .25s color;

    &:hover {
        color: var(--c-hover);
    }
}

.disclaimer {
    color: var(--c-details);
    font-size: .875rem;
    margin-top: .5rem;
}

.blue-link,
.black-link {
    color: var(--c-blue-mid-1);
    text-decoration: underline;

    .icon {
        margin-left: .5rem;
    }
}

.black-link {
    color: var(--c-black);
}

.border-top-bottom {
    border-bottom: 1px solid var(--c-grey-tr-14);
    border-top: 1px solid var(--c-grey-tr-14);
}

.accordion-content-with-border {
    border: 1px solid var(--c-grey-tr-14);
    border-radius: .5rem;
}

.form-column {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .bc-select {
        margin-bottom: 0;
    }
}

.pending-text {
    color: var(--c-warning-text);
}

.approved-text {
    color: var(--c-success-bg);
}

.rejected-text {
    color: $error-red;
}

.break-word {
    word-break: break-word;
}

.iframe-full-height {
    height: calc(100vh - 10rem);
}

.default-border-radius {
    border-radius: .5rem;
}

.disabled-td {
    opacity: 0.4;
    pointer-events: none;
}

/* Tags */

.bc-tag {

    &.blue-tag,
    &.cobalt-blue-tag,
    &.sea-green-tag {
        background-color: var(--c-blue-light);
        color: var(--c-default-bg);

        &:hover {
            background-color: var(--c-default-bg);
            color: var(--c-black);
        }
    }

    &.cobalt-blue-tag {
        background-color: $cobalt-blue;
    }

    &.sea-green-tag {
        background-color: $sea-green-background;
        color: var(--c-default-text);
    }
}

/* Pagination */

.bc-pagination {
    p {
        flex: 1 0 auto;
    }
}

/* Tabs */
nav.tabs {
    li {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.04);
        margin: .625rem 0;
        transition: all 0.2s ease-in;

        a,
        button {
            color: var(--c-default-text);
            font-size: 1.125rem;
            font-weight: 700;
            padding: 1rem 1.25rem;
        }

        &.active {
            background-color: var(--c-hover);
            border-radius: .3rem;
            margin: 0;

            a,
            button {
                color: var(--c-default-bg) !important;
            }
        }

        &.disabled {
            button {
                opacity: .4;
                pointer-events: none;
            }
        }
    }
}

/* FAQ - Accordions */
.accordion-section-list {
    .bc-accordion:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.04);
    }

    .bc-accordion:nth-child(even) {
        &[open] {
            background-color: var(--c-default-bg);
        }

        .accesses-board {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }

    &.border {
        border: 1px solid var(--c-grey-tr-14);
        border-radius: .5rem;
    }
}

/* Spinner */
.spinner-content {
    display: flex;
    justify-content: center;
    padding: .25rem 0;
}


/* ============================== */
/* Filters                        */
/* ============================== */

.select-filters {
    background-color: $widget-background-color;
    border-radius: 8px;
    margin-top: 2rem;
    max-width: 50rem;
    padding: 24px;

    .bc-select {
        margin-bottom: 0;
        max-width: 18rem;
        width: 100%;
    }

    &.full-width {
        max-width: 100%;
        width: 100%;

        &>div {
            gap: 1rem;

            .bc-select {
                margin-top: 0;
                max-width: 20rem;
                width: calc(25% - 1rem);
            }

            /* Date Range */

            .date-selection {
                flex: 0 0 auto;
                margin: 0;

                .bc-input {
                    input {
                        min-width: 15rem;
                    }
                }
            }
        }

        .filters-half-width {
            .bc-select {
                max-width: 100%;
                width: calc(50% - .5rem);
            }
        }
    }
}

.date-selection {
    margin-top: 1.5rem;

    .bc-input {
        width: 50%;
    }

    img {
        margin: 0 .625rem;
    }
}

.select-filters__btns {
    margin-top: 1.5rem;
}

.account-select {
    margin-bottom: 1.875rem;
    margin-top: 1.25rem;
    max-width: 29rem;
    width: 100%;
}

/* Date input component */
.bc-input {
    &.date-input {
        label {
            display: block;
            padding-bottom: .5rem;
        }

        input[readonly] {
            background-color: var(--c-default-bg);
            color: inherit !important;
            opacity: 1;
            padding-top: 0;
        }
    }
}

/* ============================== */
/* Skeleton                       */
/* ============================== */

.skeleton-title {
    border-radius: 1rem;
    height: 3rem;
    margin: .5rem 0;
    width: 34rem;
}

.skeleton-select {
    border-radius: 1rem;
    height: 2.5rem;
    margin: .5rem 0;
    width: 29rem;
}

/* ============================== */
/* Scrollbar                      */
/* ============================== */

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $grey-4-color $grey-2-border;
}

/* Chrome, Edge and Safari */

*::-webkit-scrollbar {
    width: .5rem;
}

*::-webkit-scrollbar-track {
    border-radius: .3rem;
    background-color: $grey-2-border;
}

*::-webkit-scrollbar-track:hover {
    background-color: $grey-2-border;
}

*::-webkit-scrollbar-track:active {
    background-color: $grey-2-border;
}

*::-webkit-scrollbar-thumb {
    border-radius: .625rem;
    background-color: $grey-4-color;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: $grey-4-color;
}

*::-webkit-scrollbar-thumb:active {
    background-color: $grey-4-color;
}

/* ============================================================ */
/* Responsive                                                   */
/* ============================================================ */

@media only screen and (min-width: 768px) {

    main.bc-container,
    .loading-page {
        min-height: calc(100vh - 22.75rem);
    }

    main.bc-container {
        .bc-step {
            align-items: center;
            flex-direction: row;
            margin-bottom: 1.75rem;
        }
    }

    h1 {
        font-size: 2.25rem;
    }
}

@media only screen and (min-width: 992px) {
    .select-filters.full-width {
        .filters-half-width {
            width: calc(50% - .5rem);
        }

        .date-range-container {
            .date-selection {
                width: calc(50% - .5rem);
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .select-filters.full-width {
        &>div .date-selection .bc-input input {
            min-width: 16rem;
        }
    }
}

@media only screen and (min-width: 1280px) {
    main.bc-container {
        padding: 2.5rem;
    }

    h1 {
        margin-top: 0;
    }
}