@import '../../styles/variables';
@import '../../styles/mixin';

.bc-header {
    /* Top Header */
    .bc-header__top {
        background-color: $grey-6-color;
        min-height: 2.625rem;
        padding: 0;

        .bc-header__top__link {
            padding: 0 1rem;

            .icon-img {
                width: 1.5rem;
            }

            .small-icon-img {
                width: 1.2rem;
            }

            &.notification__link {
                @include flex-center;
                margin-right: .5rem;

                .notification__icon {
                    position: relative;

                    .has_notification {
                        background-color: var(--c-error-bg);
                        border-radius: 100%;
                        height: .6rem;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: .6rem;
                    }
                }

                &:focus:not(:hover) {
                    background-color: transparent;
                    color: var(--c-default-bg);
                }
            }
        }

        .topmenu__text {
            display: none;
        }

        .bc-dropdown {
            @include flex-center;
            cursor: pointer;
            position: static;
            width: auto;

            .bc-dropdown-trigger {
                @include flex-center;
                padding-left: 0;
                padding-right: 0;

                img {
                    margin-right: .2rem;
                }

                .bc-icon-direction-down-fat {
                    margin-left: .4rem;
                }

                &.active {
                    .bc-icon-direction-down-fat {
                        transform: rotate(180deg);
                    }
                }
            }

            .bc-dropdown__content--right {
                max-width: 14rem;
                padding: 1.5rem;
                right: 1rem;
                top: 2.825rem;
                width: 100%;
                z-index: 9999;

                h2 {
                    color: var(--c-default-text);
                    font-size: 1rem;
                    margin: 0;
                }

                &.profile-dropdown {
                    background-color: var(--c-white);
                    border: .5px solid $grey-2-border;
                    border-top: none;
                    border-radius: 0 0 5px 5px;
                    margin-top: 0;
                    min-width: 16rem;
                    z-index: 9999;

                    ul {
                        margin: 0;

                        li {
                            margin-bottom: .8rem;

                            a,
                            button {
                                color: var(--c-black);
                                @include flex-center;
                                justify-content: flex-start;
                                padding: 0;
                                text-decoration: none;
                                width: 100%;
                                
                                .icon-img {
                                    margin-right: .4rem;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    /* Bottom Header */
    .bc-header__bottom {
        flex-direction: row;
        padding: 0 1rem 0 0;

        .bc-header__bottom__logo {
            align-items: center;
            flex-shrink: 0;
            padding: 0;
            z-index: 3;

            img {
                height: 1.7rem;
            }
        }

        .bc-header__bottom__actions {
            width: 100%;
        }

        .bc-header__bottom-end {
            background-color: var(--c-default-bg);
            left: 0;
            position: absolute;
            top: 4.5rem;
            z-index: 9;

            .bc-header__bottom__link {
                border-bottom: 1px solid $whitegrey-border;
                display: flex;
                line-height: inherit;
                padding: .7rem 1rem;
                width: 100%;

                img {
                    margin-right: .5rem;
                    max-width: 1.7rem;
                    transition: all var(--transition);
                }

                .link__label {
                    align-items: center;
                    display: inline-flex;
                    flex-grow: 1;

                    .icon {
                        color: $grey-4-color;
                        font-size: 1.2rem;
                        margin: 0 0 0 auto;
                        transform: rotate(0);
                    }
                }

                & +.bc-header__bottom__sublinks {
                    background-color: var(--c-default-bg);
                    border-bottom-right-radius: .5rem;
                    border-bottom-left-radius: .5rem;
                    display: none;
                    transition: none;
                    z-index: 9;

                    .sublinks__menu {
                        padding-right: 0;

                        .sublink {
                            border-radius: .5rem 0px 0px .5rem;
                            display: flex;
                            padding: .9rem 1.4rem;
                            position: relative;
                            text-decoration: none;
                            transition: .3s all;
                            width: 100%;

                            img {
                                margin-right: .75rem;
                            }

                            .sublink__label {
                                font-size: 1.14rem;
                                font-weight: 600;
                                margin-bottom: .25rem;
                                text-align: left;
                            }

                            .sublink__description {
                                color: var(--c-details);
                                font-size: 1rem;
                                text-align: left;
                            }

                            .arrow-icon {
                                color: $grey-4-color;
                                position: absolute;
                                right: 2rem;
                                top: 1rem;
                            }

                            &:hover {
                                background-color: var(--c-default-bg);
                                color: var(--c-default-text);
                            }

                            &.active {
                                .arrow-icon {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .sublinks__content {
                        padding: 0;
                    }

                    .sublinks__content-item {
                        display: none;
                        background-color: var(--c-default-bg);
                        padding: 1.4rem;

                        h2 {
                            color: var(--c-details);
                            font-size: 1.14rem;
                            margin-bottom: 1rem;
                            margin-top: 0;
                        }

                        .bc-badge {
                            border-radius: 8px;
                            color: $grey-4-color;
                            font-size: 1rem;
                            line-height: 1.3rem;
                            margin-bottom: .5rem;
                            padding: 0 .7rem;

                            &.blue-badge {
                                background-color: $ice-blue-background;
                            }

                            &.green-badge {
                                background-color: $sea-green-background;
                            }
                        }

                        ul {
                            padding-left: 0;

                            .sublinks__content-list-item {
                                display: flex;
                                justify-content: space-between;
                                list-style: none;
                                padding: .7rem 0;

                                a {
                                    align-items: flex-start;
                                    display: flex;
                                    justify-content: flex-start;
                                    text-decoration: none;
                                    max-width: 100%;
                                    width: auto;

                                    h3 {
                                        color: var(--c-default-text);
                                        font-size: 1rem;
                                        font-weight: 600;
                                        margin: 0;
                                    }

                                    p {
                                        color: var(--c-details);
                                        font-size: 1rem;
                                        margin: 0;
                                    }

                                    .img-left {
                                        margin-right: .9rem;
                                        max-width: 1.7rem;
                                    }

                                    .img-right {
                                        margin-left: auto;
                                        min-width: 1.2rem;
                                    }

                                    &:hover {
                                        h3 {
                                            text-decoration: underline;
                                        }
                                    }

                                    &:first-child {
                                        flex-grow: 1;
                                    }

                                    &:last-child {
                                        flex-shrink: 0;
                                    }
                                }
                            }
                        }

                        .bc-col {
                            padding: 1rem 0;
                        }

                        &.show {
                            display: block;
                        }
                    }
                }

                &.active {
                    background-color: var(--c-blue-lighter);
                    color: var(--c-blue-mid-3);
                    
                    img {
                        filter: invert(26%) sepia(98%) saturate(1641%) hue-rotate(171deg) brightness(95%) contrast(101%);
                    }

                    .link__label {
                        .icon {
                            transform: rotate(180deg);
                        }
                    }

                    &+.bc-header__bottom__sublinks {
                        display: flex;
                        height: auto;
                    }
                }

                &:hover {
                    img {
                        filter: invert(26%) sepia(98%) saturate(1641%) hue-rotate(171deg) brightness(95%) contrast(101%);
                    }
                }

                &:focus:not(.active) {
                    background: none;
                    color: inherit;
                }
            }
        }
    }
}

/* ============================================================ */
/* Responsive                                                   */
/* ============================================================ */

@media only screen and (min-width: 768px) {
    .bc-header {

        /* Top Header */
        .bc-header__top {
            .bc-dropdown {
                .bc-dropdown__content--right {
                    right: 1rem;

                    &.contacts-dropdown {
                        max-width: 44rem;
                        min-width: unset;
                        right: 1.5rem;
                        width: calc(100% - .5rem);

                        .generic-message.error {
                            margin: 1rem 0;
                        }
                    }
                }

                &.contact__dropdown {
                    margin-right: .5rem;
                }

                .bc-dropdown-trigger img {
                    margin-right: .5rem;
                }
            }

            .topmenu__text {
                display: block;
            }

            .bc-header__top__link.notification__link {
                .notification__icon {
                    margin-right: .5rem;
                }
            }
        }

        /* Bottom Header */
        .bc-header__bottom {
            padding: 0;

            .bc-container {
                padding-right: 0;
            }

            .bc-header__bottom__actions {
                max-width: 21rem;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .bc-header {
        .bc-header__bottom {
            padding-left: 0;

            .bc-header__burger {
                display: none;
            }

            .bc-header__bottom-end {
                border: 0;
                display: block;
                position: static;

                .bc-header__bottom__links {
                    flex-direction: row;
                    width: auto;
                    border-top: none;
                    padding: 0;

                    .bc-header__bottom__link {
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 0 1rem;
                        text-align: center;
                        height: 4.5rem;

                        img {
                            margin-right: 0;
                        }

                        .link__label {
                            flex-grow: 0;
                            
                            .icon {
                                margin: 0 0 0 .25rem;
                            }
                        }

                        &+.bc-header__bottom__sublinks {
                            background: linear-gradient(to left, #ffffff 50%, var(--c-white) 50%);
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: calc(4.5rem + 1px);

                            .bc-container {
                                height: 100%;

                                .sublinks__content {
                                    background-color: var(--c-default-bg);
                                }

                                .bc-cols {
                                    height: 100%;
                                    margin: 0;

                                    .sublinks__content-item {
                                        padding: 1rem 3rem 1rem 1rem;

                                        .bc-cols {
                                            margin: 0;

                                            .bc-col {
                                                padding: 1rem;

                                                &.sublinks__content-list-item {
                                                    padding: 1rem 3rem 1rem 1rem;

                                                    &:first-child {
                                                        padding-left: 0;
                                                    }
                                                }
                                            }

                                            &.submenu-cols {
                                                .bc-col:not(:first-child):last-child {
                                                    border-left: 1px solid #ccc;
                                                }
                                            }
                                        }

                                        h2 {
                                            margin-bottom: 0;
                                            padding-left: 1rem;
                                        }
                                    }
                                }
                            }

                            .sublinks__menu {
                                background-color: var(--c-white);
                                padding: 1rem 0;

                                .sublink {
                                    background-color: var(--c-white);
                                    margin-left: 2rem;
                                    width: calc(100% - 2rem);

                                    &.active {
                                        background-color: var(--c-default-bg);
                                        color: var(--c-default-text);
                                    }
                                }
                            }

                            &.simple {
                                background: var(--c-default-bg);

                                .bc-container {
                                    margin: 1rem 0;

                                    .bc-cols {
                                        margin: calc(-1 * var(--gutters))
                                    }
                                }

                                .sublinks__menu {
                                    padding: 0;
                                }
                            }
                        }

                        &.no-img {
                            padding-top: 1.7rem;
                        }
                    }
                }
            }

            .header-container {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .bc-header {
        .bc-header__bottom {
            .bc-header__bottom__actions {
                max-width: 2rem;
            }

            .bc-header__bottom-end {
                .bc-header__bottom__links {
                    justify-content: center;

                    .bc-header__bottom__link {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .bc-header {
        /* Top Header */
        .bc-header__top {
            font-size: .875rem;
            padding: 0;

            .bc-container {
                padding: 0 2.5rem;
            }

            .bc-dropdown {
                position: relative;

                .bc-dropdown__content--right {

                    &.contacts-dropdown,
                    &.profile-dropdown {
                        right: 0;
                    }

                    &.contacts-dropdown {
                        min-width: 41rem;
                        right: -10.5rem;
                    }
                }

                .bc-dropdown-trigger {
                    padding: .625rem 0;
                }
            }
        }

        .bc-header__bottom {
            .bc-container {
                padding: 0 2.5rem;

                .sublinks__content-item {
                    .bc-container {
                        padding: 0 0 0 1rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 2000px) {
    .bc-header {
        .bc-header__top {
            .bc-container {
                padding: 0 2.5rem;
            }
        }
    }
}