/* ============================== */
/* Mixin                          */
/* ============================== */

@mixin flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

@mixin flex-space-between {
    align-items: center;
    display: flex;
    justify-content: space-between;
}